<template>
  <div class="cntHome" v-if="!loading">
    <div class="cntFrase">
      <p class="animate__animated animate__fadeInLeft" v-html="home.frase"></p>
      <!-- <v-carousel class="carousel animate__animated animate__fadeInDown" cycle hide-delimiters style="height: unset">
        <v-carousel-item v-for="(foto, i) in catalogoQuadri" :key="i" :src="foto"> </v-carousel-item>
        <v-btn class="btnCatalogo" v-if="isPhone" @click="$router.push('/catalogo')">Vai Al catalogo</v-btn>
      </v-carousel> -->
      <Carousel class="carousel" :value="catalogoQuadri" :numVisible="1" :numScroll="1" autoplayInterval="3000">
        <template #item="slotProps">
          <img :src="slotProps.data" class="imgCarousel" />
        </template>
        <template #footer>
          <v-btn v-if="isPhone" class="btnCatalogo" @click="$router.push('/catalogo')" dark>Vai Al catalogo</v-btn>
        </template>
      </Carousel>
    </div>
    <hr style="width: 100%; margin: 4rem 0" />
    <div class="cntText">
      <div class="presentazione ql-editor" v-html="home.presentazione"></div>
      <div class="frame"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import utils from "../utils/utils";
import Carousel from "primevue/carousel";
export default {
  props: ["isPhone"],

  components: {
    Carousel
  },

  data() {
    return {
      catalogoQuadri: [],
      imgDraft: require("../assets/draft.jpg")
    };
  },

  async created() {
    await this.getHomeAsync();
    Object.keys(this.home).forEach(key => {
      if (key.includes("fotoCarosello") && this.home[key]) this.catalogoQuadri.push(this.home[key]);
    });
    this.setBackgroundFoto();
  },

  computed: {
    ...mapGetters({
      home: "getHome",
      loading: "getLoading"
    })
  },

  methods: {
    ...mapActions(["getHomeAsync"]),

    setBackgroundFoto() {
      var style = "height: 700px; background: url(" + this.home.foto + ") center no-repeat";
      var el = this.$el.querySelector(".frame");
      if (utils.iOS()) {
        el.style = style;
      } else {
        el.style = style + " fixed";
      }
    }
  }
};
</script>

<style lang="scss">
.cntHome {
  margin: 10rem 16px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .cntFrase {
    display: flex;
    align-items: center;
    p {
      font-size: 6rem;
      margin-left: 44px;
      margin-right: 44px;
      width: 50vw;
    }
    .carousel {
      width: 50%;
      .p-carousel-item {
        display: flex;
        align-items: center;
        .imgCarousel {
          width: 100%;
        }
      }
      .p-carousel-indicators {
        display: none;
      }
    }
  }
  .cntText {
    display: flex;
    flex-direction: column;
    .frame {
      margin-top: 1rem;
      margin-top: -6px;
    }
    .presentazione {
      width: 88%;
      align-self: center;
      background-color: #12151a;
      padding: 2rem;
      text-align: justify;
    }
  }
}

@media screen and (min-width: 960px) and (max-width: 1368px) {
  p {
    font-size: 4rem !important;
  }
}

.phone {
  .cntHome {
    margin: 70px 16px 40px;
    .cntFrase {
      display: block;
      p {
        font-size: 20px;
        text-align: center;
        margin-left: unset;
        margin-right: unset;
        width: unset;
      }
      .carousel {
        width: unset !important;
        .btnCatalogo {
          position: relative;
          bottom: 50px;
          left: calc(50% - 87px);
        }
      }
    }
    .cntText {
      .presentazione {
        width: 90%;
        padding: 1rem;
      }
    }
  }
}
</style>
